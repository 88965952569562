import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { api } from "../../api.service";
import SelectField from "./select-field";

export default function EquationBuilder({ label, value, onChange, className, inputClass, labelClass, inline }) {

    const [metrics, setMetrics] = useState()

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setMetrics(x.map(x => { return { text: x.name, value: x._id } })))

    }, [])
    const legalChars = '1234567890+-*/().';
    const onKeyDown = (e) => {
        if (legalChars.includes(e.key)) {
            onChange((value ?? '') + e.key)
        }

        if (e.key == "Backspace") {
            if (value.substring(value.length - 1) == "}") {
                let valParts = value?.split(/[{}]+/).filter(x => x.trim() != '');
                valParts.pop();
                let newVal = "";
                for (let v of valParts) {
                    if (v.startsWith("metric_")) newVal += `{${v}}`;
                    else newVal += v;
                }
                onChange(newVal)
            }
            else {
                onChange(value ? value.substring(0, value.length - 1) : '')
            }
        }

    }

    const parts = value?.split(/[{}]+/);

    return <div className={className + 'border'}>
        <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
            {label}
        </label>
        <div className="bg-neutral-50 border p-3 flex">
            {metrics && <SelectField className="w-64" options={metrics} label="Add a Metric" onChange={(val) => onChange((value ?? '') + `{metric_${val}}`)}></SelectField>}
            <div onClick={() => onChange((value ?? '') + '+')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">+</div>
            <div onClick={() => onChange((value ?? '') + '-')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">-</div>
            <div onClick={() => onChange((value ?? '') + '*')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">*</div>
            <div onClick={() => onChange((value ?? '') + '/')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">/</div>
            <div onClick={() => onChange((value ?? '') + '(')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">(</div>
            <div onClick={() => onChange((value ?? '') + ')')} className="hover:bg-neutral-400 cursor-pointer w-14 h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2">)</div>
            <div className="flex-1"></div>
            <div onClick={() => onKeyDown({ key: 'Backspace' })} className="hover:bg-neutral-400 cursor-pointer w-[75px] h-14 transition-all bg-neutral-300 text-4xl text-center font-bold text-neutral-800 p-2 px-4 shadow rounded my-auto mx-2"><ArrowLeftIcon></ArrowLeftIcon></div>

        </div>

        <div tabIndex="0" className="border focus:border-blue-500 p-2 font-semibold text-2xl " onKeyDown={onKeyDown}>
            {parts?.map(x => {
                if (x.startsWith("metric_")) {
                    return <span className="border border-dashed text-blue-500 text-lg mx-2">{metrics?.find(y => y.value == x.substring(7))?.text}</span>
                }
                else {
                    return x
                }

            })}
        </div>
    </div>
}
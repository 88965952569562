import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { api } from '../../api.service';
import Loading from '../../components/loading';
import CheckboxField from './checkbox-field';
import TextField from './text-field';

export default function ListBoxField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, onBlur }) {

    const [selectOptions, setSelectOptions] = useState(options);
    useEffect(() => {
        if (optionListName === "pcs") {
            api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "investors") {
            api(`${process.env.REACT_APP_API_URL}/admin/investor`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "metrics") {
            api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setSelectOptions(x.sort((a, b) => a.name < b.name ? -1 : 1).map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "dashboards") {
            api(`${process.env.REACT_APP_API_URL}/admin/dashboard`).then(x => setSelectOptions(x.filter(x => !x.isAggregate).map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "scorecards") {
            api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
    }, [])

    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))

    if (!selectOptions) return <Loading></Loading>
    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
            {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm ">

            <div className="border border-neutral-300 bg-neutral-100 shadow p-3">
                <TextField placeholder="Filter..." value={query} onChange={(val) => setQuery(val)}></TextField>

                <div className="max-h-96 overflow-auto" onBlur={onBlur}>
                    {filtered.map((x, i) => <div className="border-t py-2" key={x._id} >
                        <CheckboxField inline={true} label={x.text} value={value?.includes(x.value)} onChange={(val) => { return val ? (value?.includes(x.value) ? onChange(value) : onChange(value ? value.concat([x.value]) : [x.value])) : onChange(value.filter((z, j) => z != x.value)) }}></CheckboxField>
                    </div>)}
                </div>
            </div>


        </div>
    </div>
}


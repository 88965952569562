import moment from "moment";
import DocumentModal from "./components/document-modal";
import { GetComputationalMetricValue } from "./computations";

export const format_currency = (v) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return formatter.format(v)
}

export const formatMetric = (value, type, metric, metricValues, metrics) => {
  if (type == "computational") {
    return (Math.round(GetComputationalMetricValue(metric, metricValues, null, metrics) * 100) / 100)
  }

  if (!value) return '-'

  if (type == 0) {
    return value
  }
  if (type == 1) {
    return value
  }
  if (type == 2) {
    return value
  }
  if (type == 3) {
    return format_currency(value)
  }
  if (type == 4) {
    return value + "%"
  }
  if (type == 5) {
    return moment(value).format("YYYY/MM/DD")
  }
  if (type == 6) {
    return value
  }
  if (type == 7) {
    return value.join(', ')
  }
  if (type == 8) {
    return value === false ? "No" : value === true ? "Yes" : ''
  }
  if (type == 9) {
    return <DocumentModal id={value}></DocumentModal>
  }
  else return value
}
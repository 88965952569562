import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import ListBoxField from "./fields/listbox-field";

export default function PcForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader showBackButton headline="Manage Portfolio Company"></PageHeader>
        <FormWrapper url="admin/pc" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <ImageField label="Logo" value={values.logo} onChange={(val) => setValues({ ...values, logo: val })}></ImageField>
                <ImageField label="Cover Photo" value={values.coverPhoto} onChange={(val) => setValues({ ...values, coverPhoto: val })}></ImageField>
                <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                <TextField label="Sector" value={values.sector} onChange={(val) => setValues({ ...values, sector: val })}></TextField>
                <DateField label="Date Joined" value={values.date} onChange={(val) => setValues({ ...values, date: val })}></DateField>
                <div></div>
                <TextField label="Website Url" value={values.websiteUrl} onChange={(val) => setValues({ ...values, websiteUrl: val })}></TextField>
                <TextField label="CEO" value={values.ceo} onChange={(val) => setValues({ ...values, ceo: val })}></TextField>
                <TextAreaField className="col-span-2" label="About" value={values.about} onChange={(val) => setValues({ ...values, about: val })}></TextAreaField>
                <div className="col-span-2">
                    <hr className="my-3" />
                    <ListBoxField optionListName={"metrics"} label="Metrics" value={values.metrics} onChange={(val) => setValues({ ...values, metrics: val })}></ListBoxField>
                </div>
                <div className="col-span-2">
                    <hr className="my-3" />
                    <ListBoxField optionListName={"dashboards"} label="Dashboards (visible to admins)" value={values.dashboards} onChange={(val) => setValues({ ...values, dashboards: val })}></ListBoxField>
                </div>
                <div className="col-span-2">
                    <hr className="my-3" />
                    <ListBoxField optionListName={"dashboards"} label="Dashboards (visible to PC)" value={values.dashboards_pc} onChange={(val) => setValues({ ...values, dashboards_pc: val })}></ListBoxField>
                </div>
                <div className="col-span-2">
                    <hr className="my-3" />
                    <ListBoxField optionListName={"scorecards"} label="Scorecards" value={values.scorecards} onChange={(val) => setValues({ ...values, scorecards: val })}></ListBoxField>
                </div>
            </div>}
        </FormWrapper>
    </div>
}
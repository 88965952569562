
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { api } from './api.service'
import Loading from './components/loading'
import PageHeader from './components/page-header'
import Dashboard from './dashboard'

export default function PcDashboard() {
  const [pc, setPc] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc`).then(x => { { console.log(x); setPc(x.portfolioCompany) }; }); }, [])

  const [data, setData] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc`).then(x => { setData(x); }); }, [])
  const [dashboards, setDashboards] = useState([])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/dashboards`).then(x => { setDashboards(x); }); }, [])
  const navigate = useNavigate();
  if (!data) return <Loading></Loading>

  return (<>
    <div className="hidden bg-amber-100 text-amber-500 bg-red-100 text-red-500 bg-red-500/30"></div>
    <PageHeader image={data.portfolioCompany?.logo} headline={data.portfolioCompany?.name}
    // actions={<div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
    //   <button
    //     type="button"
    //     className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    //   >
    //     Update Information
    //   </button>
    //   <button
    //     type="button"
    //     className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
    //   >
    //     Contact Launchpad Angels
    //   </button>
    // </div>}
    ></PageHeader>

    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Outstanding Tasks</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            These are the outstanding tasks for you to complete.
          </p>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mt-5">
          <ul role="list" className="divide-y divide-gray-200">
            {data.tasks?.map((task, i) => (
              <li key={task.id}>
                <a href={`/0/${task.type === "form" ? 'form/' + task.id : 'metrics'}`} className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{task.title}</p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className={`inline-flex rounded-full bg-neutral-100 px-2 text-xs font-semibold leading-5 text-neutral-500`}>
                          {task.type}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <TagIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          {task.category}
                        </p>
                      </div>
                      {task.dueDate && <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p>
                          Due {new Date(task.dueDate).toDateString()}
                        </p>
                      </div>}
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{dashboards[0].name}</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">

          </p>
        </div>


        {dashboards[0] && <Dashboard metrics={pc.metrics} _metricValues={pc.metricValues} dashboard={dashboards[0]}></Dashboard>}

        {/* <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Messages</h3>
            </div>
            <div className="bg-white rounded-lg shadow overflow-hidden mt-5">
              <ul role="list" className="divide-y divide-gray-200">
                <div className="p-5 text-center text-lg font-semibold text-neutral-700">No Messages</div>

              </ul>
            </div>
          </div>
          <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-900">News</h3>
            </div>
            <div className="bg-white rounded-lg shadow overflow-hidden mt-5">
              <ul role="list" className="divide-y divide-gray-200  ">
                {data.news?.map((news) => (
                  <li
                    key={news._id}
                    className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50"
                  >
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <a href="#" className="block focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          <p className="truncate text-sm font-medium text-gray-900">{news.title}</p>
                          <p className="truncate text-sm text-gray-500">{news.subtitle}</p>
                        </a>
                      </div>
                      <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                        {news.date && new Date(news.date).toDateString()}
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-600 line-clamp-2">{news.body}</p>
                    </div>
                  </li>
                ))}
                {!data.news.length && <div className="p-5 text-center text-lg font-semibold text-neutral-700">No new news</div>}
              </ul>
            </div>

          </div>
        </div> */}

      </div>
    </div>



  </>

  )
}

import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import Button from "../components/button";
import NumberField from "./fields/number-field";
import PercentageField from "./fields/percentage-field";

export default function InvestmentForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader headline="Manage Investment"></PageHeader>
        <FormWrapper url="admin/investment" id={id} {...props} callback={() => navigate('/2/investments')}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <CurrencyField label="Investment Amount" value={values.invested} onChange={(val) => setValues({ ...values, invested: val })}></CurrencyField>
                <DateField label="Investment Date" value={values.date} onChange={(val) => setValues({ ...values, date: val })}></DateField>
                <SelectField optionListName="pcs" label="Portfolio Company" value={values.portfolioCompany} onChange={(val) => setValues({ ...values, portfolioCompany: val })}></SelectField>
                <SelectField optionListName="investors" label="Investor" value={values.investor} onChange={(val) => setValues({ ...values, investor: val })}></SelectField>
                <TextAreaField className="col-span-2" label="Notes" value={values.notes} onChange={(val) => setValues({ ...values, notes: val })}></TextAreaField>
                <div className="col-span-2">
                    {values.ownership?.map((x, i) => <div className="my-3 border-y">
                        <div className="grid grid-cols-3">
                            <PercentageField label="Ownership" value={x.ownership} onChange={(val) => setValues({ ...values, ownership: values.ownership.map((y, j) => j === i ? { ...y, ownership: val } : y) })}></PercentageField>
                            <DateField label="Date" value={x.date} onChange={(val) => setValues({ ...values, ownership: values.ownership.map((y, j) => j === i ? { ...y, date: val } : y) })}></DateField>
                        </div>
                        <TextAreaField className="col-span-2" label="Notes" value={x.notes} onChange={(val) => setValues({ ...values, ownership: values.ownership.map((y, j) => j === i ? { ...y, notes: val } : y) })}></TextAreaField>
                    </div>)}

                </div>

                <Button text="Add Ownership Update" onClick={() => setValues({ ...values, ownership: values.ownership ? values.ownership.concat([{}]) : [{}] })}></Button>

            </div>}
        </FormWrapper>
    </div>
}
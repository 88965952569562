import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import CheckboxField from "./fields/checkbox-field";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
export default function DashboardForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();

    return <div>
        <PageHeader headline="Manage Dashboard" showBackButton></PageHeader>
        <FormWrapper url="admin/dashboard" id={id} {...props} callback={() => navigate('/2/dashboards')}>
            {(values, setValues) => <div className="">
                <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">General</div>

                <div className="grid grid-cols-4 gap-4">
                    <TextField className="col-span-3" label="Dashboard Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                    <CheckboxField label="Aggregate Dashboard" value={values.isAggregate} onChange={(val) => setValues({ ...values, isAggregate: val })}></CheckboxField>
                </div>

                <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">Charts & Statistics</div>

                <div className="my-2 bg-neutral-50">
                    <div className="flex flex-col">
                        {values.data?.map((x, i) =>
                            <div className="my-2 relative">
                                <div className="absolute right-2 top-2" onClick={() => setValues({ ...values, data: values.data.filter((y, j) => j !== i) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                                <div className="bg-neutral-200 rounded-t-lg py-2 px-5">
                                    <div className="text-sm text-neutral-600">Columns</div>
                                    <div className="inline-flex rounded-md shadow-sm">
                                        <button onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, cols: 1 } : y) })}
                                            type="button"
                                            className={`${(!x.cols || x.cols == 1) ? 'bg-blue-500 text-white' : 'bg-white'} relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                                        >
                                            1
                                        </button>
                                        <button onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, cols: 2 } : y) })}
                                            type="button"
                                            className={`${(x.cols == 2) ? 'bg-blue-500 text-white' : 'bg-white'}  relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                                        >
                                            2
                                        </button>
                                        <button onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, cols: 3 } : y) })}
                                            type="button"
                                            className={`${(x.cols == 3) ? 'bg-blue-500 text-white' : 'bg-white'}  relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                                        >
                                            3
                                        </button>
                                        <button onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, cols: 4 } : y) })}
                                            type="button"
                                            className={`${(x.cols == 4) ? 'bg-blue-500 text-white' : 'bg-white'}  relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                                        >
                                            4
                                        </button>

                                    </div>
                                </div>

                                <div className={` p-5  relative min-h-20 grid grid-cols-${x.cols ?? 1} gap-4`}>
                                    {Array.from('x'.repeat(x.cols ?? 1)).map(function () { }).map((block, bi) => {
                                        return <div className="bg-white rounded-lg shadow-lg p-5" key={i + '' + bi}>
                                            <div className="grid grid-cols-4 gap-4">
                                                <SelectField className={values.isAggregate ? 'col-span-3' : 'col-span-4'} label="Display" optionListName={'display-types'} value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].display : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], display: val } : { display: val } } : { [bi]: { display: val } } } : y) })}></SelectField>

                                                {values.isAggregate && <CheckboxField label="Agg Data" value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].aggregate : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], aggregate: val } : { aggregate: val } } : { [bi]: { aggregate: val } } } : y) })}></CheckboxField>}
                                            </div>
                                            {x.blocks && x.blocks[bi] && x.blocks[bi].display == 0 && <div>
                                                <div className="flex select-none">
                                                    <div onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], source: 'metric' } : { source: 'metric' } } : { [bi]: { source: 'metric' } } } : y) })} className={`${(x.blocks[bi].source == 'metric' || !x.blocks[bi].source) ? 'border-b-2 border-blue-300 text-blue-500 ' : 'border-b border-neutral-400 text-neutral-700 cursor-pointer'} text-center text-sm flex-1 border-b  font-semibold `}>Metric</div>
                                                    <div onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], source: 'scorecard' } : { source: 'scorecard' } } : { [bi]: { source: 'scorecard' } } } : y) })} className={`${x.blocks[bi].source == 'scorecard' ? 'border-b-2 border-blue-300 text-blue-500 ' : 'border-b border-neutral-400 text-neutral-700 cursor-pointer'} text-center text-sm flex-1 border-b font-semibold `}>Scorecard</div>
                                                </div>
                                                {(!x.blocks[bi].source || x.blocks[bi].source == "metric") && <SelectField optionListName={'metrics'} value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].metric : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], metric: val } : { metric: val } } : { [bi]: { metric: val } } } : y) })}></SelectField>}
                                                {(x.blocks[bi].source == "scorecard") && <SelectField optionListName={'scorecards'} value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].scorecard : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], scorecard: val } : { scorecard: val } } : { [bi]: { scorecard: val } } } : y) })}></SelectField>}
                                            </div>}
                                            {x.blocks && x.blocks[bi] && (x.blocks[bi].display == 1 || x.blocks[bi].display == 2 || x.blocks[bi].display == 3 || x.blocks[bi].display == 4 || x.blocks[bi].display == 7) && <div>

                                                {x.blocks[bi].metrics?.map((metric, mi) => <div>
                                                    <div className="flex select-none">
                                                        <div onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: { ...y.blocks, [bi]: { ...y.blocks[bi], metrics: y.blocks[bi].metrics.map((met, j) => j === mi ? { ...met, source: 'metric' } : met) } } } : y) })} className={`${(metric.source == 'metric' || !metric.source) ? 'border-b-2 border-blue-300 text-blue-500 ' : 'border-b border-neutral-400 text-neutral-700 cursor-pointer'} text-center text-sm flex-1 border-b  font-semibold `}>Metric</div>
                                                        <div onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: { ...y.blocks, [bi]: { ...y.blocks[bi], metrics: y.blocks[bi].metrics.map((met, j) => j === mi ? { ...met, source: 'scorecard' } : met) } } } : y) })} className={`${metric.source == 'scorecard' ? 'border-b-2 border-blue-300 text-blue-500 ' : 'border-b border-neutral-400 text-neutral-700 cursor-pointer'} text-center text-sm flex-1 border-b font-semibold `}>Scorecard</div>
                                                    </div>
                                                    {(!metric.source || metric.source == "metric") && <SelectField optionListName={'metrics'} value={metric.metric} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: { ...y.blocks, [bi]: { ...y.blocks[bi], metrics: y.blocks[bi].metrics.map((met, j) => j === mi ? { ...met, metric: val } : met) } } } : y) })}></SelectField>}
                                                    {(metric.source == "scorecard") && <SelectField optionListName={'scorecards'} value={metric.scorecard} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: { ...y.blocks, [bi]: { ...y.blocks[bi], metrics: y.blocks[bi].metrics.map((met, j) => j === mi ? { ...met, scorecard: val } : met) } } } : y) })}></SelectField>}
                                                </div>)}

                                                <div onClick={() => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], metrics: y.blocks[bi].metrics ? y.blocks[bi].metrics.concat([{}]) : [{}] } : { metrics: [{}] } } : { [bi]: { metrics: [{}] } } } : y) })} className="p-1 border border-dashed select-none font-bold text-neutral-500 text-center rounded-md">+ metric</div>
                                            </div>}
                                            {x.blocks && x.blocks[bi] && x.blocks[bi].display == 5 && <div>
                                                <TextField label="Title" value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].title : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], title: val } : { title: val } } : { [bi]: { title: val } } } : y) })}></TextField>
                                            </div>}
                                            {x.blocks && x.blocks[bi] && x.blocks[bi].display == 6 && <div>
                                                <TextField label="Title" value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].title : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], title: val } : { title: val } } : { [bi]: { title: val } } } : y) })}></TextField>
                                                <TextAreaField label="Text" value={(x.blocks && x.blocks[bi]) ? x.blocks[bi].text : ''} onChange={(val) => setValues({ ...values, data: values.data.map((y, j) => j === i ? { ...y, blocks: y.blocks ? { ...y.blocks, [bi]: y.blocks[bi] ? { ...y.blocks[bi], text: val } : { text: val } } : { [bi]: { text: val } } } : y) })}></TextAreaField>

                                            </div>}
                                        </div>
                                    })}
                                </div>
                            </div>

                        )}
                    </div>


                    <button
                        onClick={() => { setValues({ ...values, data: values.data ? values.data.concat([{}]) : [{}] }) }}
                        type="button"
                        className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        <PlusCircleIcon className="w-16 h-16 text-neutral-400 mx-auto"></PlusCircleIcon>
                        <span className="mt-2 block text-sm font-semibold text-gray-900">Add Row</span>
                    </button>

                </div>

            </div>}
        </FormWrapper>
    </div>
}

import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import logo from "./logo.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import doit from "./logos/doit.png"
import flipside from "./logos/flipside.png"
import playmix from "./logos/playmix.png"
import quizr from "./logos/quizr.png"
import spellchecker from "./logos/spellchecker.png"
import taskmate from "./logos/taskmate.png"
import zippy from "./logos/zippy.png"
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import PageHeader from './components/page-header'
import { api } from './api.service'
import { useEffect, useState } from 'react'
import Loading from './components/loading'
import PcCard from './components/pc-card'
import InvestorCard from './components/investor-card'
import { formatMetric, format_currency } from './formatter'
import { DateStringToDate } from './date.service'
import moment from 'moment'

const getMetricValue = (mid, metrics, _metricValues, pcs, date) => {
  let aggValue = 0
  for (let pc of pcs) {
    let metricValues = _metricValues.slice().filter(a => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) <= date).sort((a, b) => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, metrics.find(x => x._id == b.metric)?.interval) ? 1 : -1).filter(x => x.portfolioCompany == pc._id)
    let metric = metrics.find(y => y._id == mid)
    if (!metric?.computational) {
      let val = (+metricValues.slice().reverse().find(y => y.metric == mid && y.value)?.value);
      if (val && !isNaN(val)) aggValue += val;
    }
    else {
      aggValue += (+formatMetric('', 'computational', metric, metricValues.slice().reverse(), metrics)) ?? 0
    }
  }

  return Math.round(aggValue * 100) / 100

}
export default function AdminDashboard() {
  const [data, setData] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin`).then(x => { console.log(x); setData(x) }); }, [])

  const navigate = useNavigate();
  if (!data) return <Loading></Loading>
  const stats = [
    { name: 'Total No. of PCs', stat: data.totalPcs.now, previousStat: data.totalPcs.lq, formatter: (v) => v },
    { name: data.metrics?.find(x => x._id == data.primaryMetric)?.name, stat: getMetricValue(data.primaryMetric, data.metrics, data.metricValues, data.pcs, moment().toDate()), previousStat: getMetricValue(data.primaryMetric, data.metrics, data.metricValues, data.pcs, moment().startOf('quarter').add(-1, 'quarter').toDate()) },
    { name: data.metrics?.find(x => x._id == data.secondaryMetric)?.name, stat: getMetricValue(data.secondaryMetric, data.metrics, data.metricValues, data.pcs, moment().toDate()), previousStat: getMetricValue(data.secondaryMetric, data.metrics, data.metricValues, data.pcs, moment().startOf('quarter').add(-1, 'quarter').toDate()) },
  ]

  return (<>
    <PageHeader headline="Dashboard"></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5"></div>
        <Stats stats={stats}></Stats>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <div className="border-b border-gray-200 pb-5 mt-5">
              <div onClick={() => navigate("/2/pcs")} className="float-right font-semibold text-neutral-500 text-md cursor-pointer underline">See All</div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Companies</h3>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-5">
              {data.pcs.map(x => <PcCard onClick={() => navigate(`/2/pc/${x._id}`)} pc={x}></PcCard>)}
            </div>
          </div>
          {/* <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <div  onClick={() => navigate("/2/investors")} className="float-right font-semibold text-neutral-500 text-md cursor-pointer underline">See All</div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Investors</h3>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-5">
              {data.investors.map(x => <InvestorCard onClick={() => navigate(`/2/investor/${x._id}`)} investor={x}></InvestorCard>)}
            </div>
          </div> */}
        </div>

      </div>
    </div>
  </>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Stats({ stats }) {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Statistics (vs last quarter)</h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item, ii) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex flex-col items-baseline text-2xl font-semibold text-blue-600">
                <div>{item.stat} <sup>{ii > 0 && 'kgCO2e'}</sup></div>
                <div className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat} <sup>{ii > 0 && 'kgCO2e'}</sup></div>
              </div>

              <div
                className={classNames(
                  item.previousStat < item.stat && ii == 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {ii == 0 && <>
                  {item.previousStat < item.stat ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}
                </>}

                {ii > 0 && <>
                  {item.previousStat < item.stat ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  )}
                </>}


                <span className="sr-only"> {item.previousStat < item.stat ? 'Increased' : 'Decreased'} by </span>
                {Math.round((item.stat - item.previousStat) / item.previousStat * 100)}%
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

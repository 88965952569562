import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'

import { CheckIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'

const steps = [
  { name: 'Introduction', description: 'Welcome to the Launchpad Angels onboarding questionnaire.', href: '#', status: 'complete' },
  {
    name: 'Business Information',
    description: 'Basic information about your business',
    href: '#',
    status: 'current',
  },
  { name: 'Product', description: 'Details about your product and traction', href: '#', status: 'upcoming' },
  { name: 'Shareholder Info', description: 'Details about your shareholders', href: '#', status: 'upcoming' },
  { name: 'Documents', description: 'Required documents', href: '#', status: 'upcoming' },
]
export default function PcTask() {

  const [section, setSection] = useState(0)

  return (<>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <div>
                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Onboarding Questionnaire
                  </h1>
                  <div className="text-sm leading-7 text-gray-600">The standard Launchpad Angels onboarding questionnaire. It should only take 15-20 minutes to complete.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <div className="ml-2 flex flex-shrink-0 h-5">
              <p className={`inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-500`}>
                Due {new Date(new Date().setDate(new Date().getDate() + 4)).toDateString()}
              </p>
            </div>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">
        <div className="mt-10 shadow rounded-lg bg-white">
          <div className="grid grid-cols-3">
            <div className="border-r border-neutral-300 p-5">
              <div className="border-b border-neutral-200 text-xl font-semibold text-neutral-800 pb-5 mb-5">
                Sections
              </div>
              <nav aria-label="Progress">
                <ol role="list" className="overflow-hidden">
                  {steps.map((step, stepIdx) => (
                    <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                      {stepIdx < section ? (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600" aria-hidden="true" />
                          ) : null}
                          <a href={step.href} className="group relative flex items-start">
                            <span className="flex h-9 items-center">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                                <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-sm font-medium">{step.name}</span>
                              <span className="text-sm text-gray-500">{step.description}</span>
                            </span>
                          </a>
                        </>
                      ) : stepIdx === section ? (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                          ) : null}
                          <a href={step.href} className="group relative flex items-start" aria-current="step">
                            <span className="flex h-9 items-center" aria-hidden="true">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                                <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-sm font-medium text-blue-600">{step.name}</span>
                              <span className="text-sm text-gray-500">{step.description}</span>
                            </span>
                          </a>
                        </>
                      ) : (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                          ) : null}
                          <a href={step.href} className="group relative flex items-start">
                            <span className="flex h-9 items-center" aria-hidden="true">
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col">
                              <span className="text-sm font-medium text-gray-500">{step.name}</span>
                              <span className="text-sm text-gray-500">{step.description}</span>
                            </span>
                          </a>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
            <div className="col-span-2 p-5">

              {section === 0 && <div className="flex flex-col h-full">
                <div className="flex-1">

                  <div className="font-bold text-neutral-800 text-2xl text-center">Introduction</div>
                  <div className="text-neutral-600 text-lg text-center my-8">Welcome to the Launchpad Angels onboarding questionnaire.</div>
                  <div className="text-neutral-600 text-md text-center my-5">The questionnaire will ask you for business information, product details, and shareholder information.</div>
                  <div className="text-neutral-600 text-md text-center my-5">You will also need to upload your business plan, insurance documents, and founding agreement.</div>
                  <div className="text-neutral-600 text-md text-center my-5">Click next below to get started.</div>
                </div>
                <div>

                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    <ChevronLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Previous
                  </button>
                  <button
                    type="button"
                    className="float-right inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Next
                    <ChevronRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>

              </div>}

            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

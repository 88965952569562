
import { CalendarIcon, CheckCircleIcon, ClockIcon, DocumentIcon, MapPinIcon, PaperClipIcon, TagIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import Table from './components/table'
import { BuildingOfficeIcon, CreditCardIcon, UserIcon, UsersIcon } from '@heroicons/react/20/solid'
import { BoltIcon, ChartBarIcon, GlobeAltIcon, SunIcon, TableCellsIcon, CurrencyPoundIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react'
import PageHeader from './components/page-header'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from './api.service'
import Loading from './components/loading'
import PcCard from './components/pc-card'
import { format_currency } from './formatter'


export default function AdminInvestor(props) {

  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const params = useParams();
  let id = params.id ?? props.id;
  const navigate = useNavigate();
  const [data, setData] = useState(null)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/investor/${id}`).then(x => setData(x)) }, [])
  if (!data) return <Loading></Loading>
  return (<>
    <Modal open={open} setOpen={setOpen}></Modal>
    <Modal2 open={open2} setOpen={setOpen2}></Modal2>
    <Modal3 open={open3} setOpen={setOpen3}></Modal3>

    <PageHeader image={data.picture} headline={data.name}></PageHeader>

    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5 bg-white shadow rounded-lg p-5">
          <Tabs ti={tab} setTab={setTab}></Tabs>
          {tab === 0 && <div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">{data.name}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Location</dt>
                  <dd className="mt-1 text-sm text-gray-900">{data.location}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Invested</dt>
                  <dd className="mt-1 text-sm text-gray-900">{format_currency(data.investments.reduce((acc, x) => acc += x.invested, 0))}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">First Investment Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">{new Date(data.investments?.sort((a, b) => new Date(a) < new Date(b) ? -1 : 1)[0]?.date).toDateString()}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Portfolio EV</dt>
                  <dd className="mt-1 text-sm text-gray-900">{data.Ev ? 'Portfolio EV ' + format_currency(data.Ev) : 'No Valuations'}</dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {data.about}
                  </dd>
                </div>
              </dl>
            </div>
          </div>}

          {tab === 1 && <div>
            <div className="grid grid-cols-4 gap-4">
              {data.investments?.map(x => <PcCard onClick={() => navigate('/2/pc/' + x.portfolioCompany._id)} pc={x.portfolioCompany ?? {}}></PcCard>)}
            </div>

          </div>}

        </div>
      </div>
    </div >
  </>
  )
}

const tabs = [
  { name: 'Overview', href: '#', icon: GlobeAltIcon, current: false },
  { name: 'Investments', href: '#', icon: CurrencyPoundIcon, current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab }) {
  return (
    <div>

      <div className="hidden sm:block select-none">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

// const PcCard = ({ img, name, industry, invested, own, onClick }) => {
//   return <div onClick={onClick} className="cursor-pointer rounded-lg overflow-hidden shadow-lg bg-white flex flex-col">
//     <div className="p-5">
//       <img src={img} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5" />
//       <div className="text-neutral-800 text-xl font-semibold">{name}</div>
//       <div className="text-neutral-500 text-sm mb-4">{industry}</div>
//       <div className="flex justify-between">
//         <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
//         <div className="text-neutral-700 font-semibold">Own {own}%</div>
//       </div>
//     </div>

//     <div className="hover:bg-blue-900 bg-blue-700 p-2 text-white text-center text-xl font-semibold">Manage</div>
//   </div>
// }


function Modal({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Investment
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="text-neutral-700 text-sm">Add a new investment to Andrew Hall's account</div>

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Company
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                        >
                          <option>Company...</option>

                          <option>DOIT</option>
                          <option>FlipSide</option>
                          <option>playmix</option>
                          <option>Quizr</option>
                          <option>Spell Checker</option>
                          <option>Task Mate</option>
                          <option>Zippy</option>
                        </select>
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="value"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Value
                        </label>
                        <input
                          type="number"
                          name="value"
                          id="value"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="£..."
                        />
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="equity"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Equity
                        </label>
                        <input
                          type="number"
                          name="equity"
                          id="equity"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="...%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Modal2({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Andrew Hall {'<>'} Community Wheels
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Company
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          disabled
                        >
                          <option>Community Wheels</option>
                        </select>
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="value"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Value
                        </label>
                        <input
                          type="number"
                          name="value"
                          id="value"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="£..."
                          value="25"
                        />
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="equity"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Equity
                        </label>
                        <input
                          type="number"
                          name="equity"
                          id="equity"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="...%"
                          value="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    onClick={() => setOpen(false)}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="ml-5 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Modal3({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <ChartBarIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Send Report
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="text-neutral-700 text-sm">Send a report to Andrew Hall</div>

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Report
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"

                        >
                          <option>Report...</option>

                          <option>Buzzr ESG Report</option>
                          <option>Buzzr Product Report</option>
                          <option>Chat Bubble DEI Report</option>
                          <option>Digical Investment Report</option>
                        </select>
                      </div>


                      <div className="relative my-5 text-left">
                        <label
                          htmlFor="equity"
                          className="block relative bg-white px-1 text-xs font-medium text-gray-900 mb-3"
                        >
                          Automatically send updates
                        </label>
                        <Slider></Slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}





function Slider() {
  const [enabled, setEnabled] = useState(false)

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? 'bg-blue-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}

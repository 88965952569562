import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import SelectField from "./fields/select-field";
import PasswordField from "./fields/password-field";
import { useNavigate, useParams } from "react-router-dom";

export default function AdminSettingsForm(props) {
    const params = useParams();
    const navigate = useNavigate();

    return <div>
        <PageHeader headline="Settings" subtitle={"Manage portal wide settings"}  ></PageHeader>
        <FormWrapper noId={true} url="admin/settings" {...props} callback={() => navigate('/2')}>
            {(values, setValues) =>
                <>
                    <div className="grid grid-cols-2 gap-2">
                        <SelectField optionListName="metrics" label="Primary Metric" value={values.settings?.primaryMetric} onChange={(val) => setValues({ ...values, settings: values.settings ? { ...values.settings, primaryMetric: val } : { primaryMetric: val } })}></SelectField>
                        <SelectField optionListName="metrics" label="Secondary Metric" value={values.settings?.secondaryMetric} onChange={(val) => setValues({ ...values, settings: values.settings ? { ...values.settings, secondaryMetric: val } : { secondaryMetric: val } })}></SelectField>
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}
import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import NumberField from "./fields/number-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import Button from "../components/button";
import CheckboxField from "./fields/checkbox-field";
import SelectField from "./fields/select-field";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { api } from "../api.service";
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import Loading from "../components/loading";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ListBoxField from "./fields/listbox-field";
import CurrencyField from "./fields/currency-field";
import PercentageField from "./fields/percentage-field";
import FileField from "./fields/file-field";
import { DateStringToDate } from "../date.service";

export default function FormResponseForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    let id = params.id ?? props.id;
    const [data, setData] = useState();
    const [_metricValues, setMetricValues] = useState();
    const [allMetrics, setAllMetrics] = useState();
    const [currentSection, setCurrentSection] = useState(0);

    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/form/${id}`).then(x => { setData(x) }) }, [])
    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/all-metrics`).then(x => { setAllMetrics(x) }) }, [])
    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/metric-value`).then(x => { setMetricValues(x) }) }, [])

    if (!data || !allMetrics || !_metricValues) return <Loading></Loading>

    let metricValues = _metricValues.sort((a, b) => DateStringToDate(a.date, allMetrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, allMetrics.find(x => x._id == b.metric)?.interval) ? -1 : 1)

    const rawContentState = data.sections[currentSection]?.description ? data.sections[currentSection]?.description.entityMap ? data.sections[currentSection]?.description : { entityMap: {}, ...data.sections[currentSection]?.description } : { blocks: [], entityMap: {} }
    const markup = draftToHtml(rawContentState);

    return <div>
        <FormWrapper noStyle={true} hideButton={true} url="pc/form-response" includeIdInPost={true} id={id} {...props} callback={() => navigate('/0')}>
            {(values, setValues, submit) =>
                <>
                    <PageHeader headline={data.name} actions={<div className="flex"><Button onClick={() => submit()} text="Save & Continue Later" className="bg-neutral-500 mr-5"></Button><Button text="Submit" onClick={() => { submit({ ...values, status: 'submitted' }) }}></Button></div>}></PageHeader>

                    <div className=" m-10 bg-white rounded-lg shadow-lg p-10">
                        <div className="flex">
                            {data.sections.length > 1 && <div className="w-96">
                                <div className="text-xl font-semibold text-neutral-800 pb-5 mb-5">
                                    Sections
                                </div>
                                <nav aria-label="Progress">
                                    <ol role="list" className="overflow-hidden">
                                        {data.sections.map((section, sectionIndex) => (
                                            <li onClick={() => setCurrentSection(sectionIndex)} key={section.id} className={(sectionIndex !== data.sections.length - 1 ? 'pb-10' : '') + ' select-none cursor-pointer relative'}>
                                                {sectionIndex < currentSection ? (
                                                    <>
                                                        {sectionIndex !== data.sections.length - 1 ? (
                                                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={section.href} className="group relative flex items-start">
                                                            <span className="flex h-9 items-center">
                                                                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                                                                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 flex min-w-0 flex-col">
                                                                <span className="text-sm font-medium">{section.title}</span>
                                                                <span className="text-sm text-gray-500">{section.details}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                ) : sectionIndex === currentSection ? (
                                                    <>
                                                        {sectionIndex !== data.sections.length - 1 ? (
                                                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={section.href} className="group relative flex items-start" aria-current="section">
                                                            <span className="flex h-9 items-center" aria-hidden="true">
                                                                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                                                                    <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 flex min-w-0 flex-col">
                                                                <span className="text-sm font-medium text-blue-600">{section.title}</span>
                                                                <span className="text-sm text-gray-500">{section.details}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        {sectionIndex !== data.sections.length - 1 ? (
                                                            <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                                        ) : null}
                                                        <a href={section.href} className="group relative flex items-start">
                                                            <span className="flex h-9 items-center" aria-hidden="true">
                                                                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                                                    <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                                                </span>
                                                            </span>
                                                            <span className="ml-4 flex min-w-0 flex-col">
                                                                <span className="text-sm font-medium text-gray-500">{section.title}</span>
                                                                <span className="text-sm text-gray-500">{section.details}</span>
                                                            </span>
                                                        </a>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                </nav>
                            </div>}
                            <div className="flex-1 flex flex-col">
                                <div className="flex-1 mb-10 max-w-2xl w-full mx-auto">
                                    <div dangerouslySetInnerHTML={{ __html: markup }}></div>
                                    {data.sections[currentSection]?.fields?.map(x => <div className="my-5 relative" key={x.id}>
                                        {metricValues.find(y => y.metric == x.metric) && (!values.values || !values.values[x.id] )&& <div onClick={() => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: metricValues.find(y => y.metric == x.metric).value } : { [x.id]: metricValues.find(y => y.metric == x.metric).value } })} className="absolute right-0 text-sm font-medium text-neutral-600 cursor-pointer border border-transparent hover:bg-blue-50 hover:border-blue-500 rounded-lg p-1">
                                        <LightBulbIcon className="w-5 inline"/>{metricValues.find(y => y.metric == x.metric).value} 
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 0 || x.type === 0) && <div>
                                            <TextField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 1 || x.type === 1) && <div>
                                            <TextAreaField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 2 || x.type === 2) && <div>
                                            <NumberField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 3 || x.type === 3) && <div>
                                            <CurrencyField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 4 || x.type === 4) && <div>
                                            <PercentageField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 5 || x.type === 5) && <div>
                                            <DateField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 6 || x.type === 6) && <div>
                                            <SelectField options={x.options} label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 7 || x.type === 7) && <div>
                                            <ListBoxField options={x.options} label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 8 || x.type === 8) && <div>
                                            <CheckboxField label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        {(allMetrics.find(y => y._id == x.metric)?.type === 9 || x.type === 9) && <div>
                                            <FileField endpoint="document" label={x.label} value={values.values ? values.values[x.id] : null} onChange={(val) => setValues({ ...values, values: values.values ? { ...values.values, [x.id]: val } : { [x.id]: val } })} />
                                        </div>}
                                        <div className="text-neutral-500 text-sm">{x.helpText}</div>
                                    </div>)}
                                </div>
                                <div className="max-w-2xl w-full mx-auto">
                                    {currentSection > 0 && <button
                                        type="button"
                                        onClick={() => setCurrentSection(currentSection - 1)}
                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        <ChevronLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Previous
                                    </button>}
                                    {currentSection < data.sections.length - 1 && <button
                                        type="button"
                                        onClick={() => setCurrentSection(+currentSection + 1)}
                                        className="float-right inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        Next
                                        <ChevronRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </button>}
                                </div>


                            </div>
                        </div>
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}
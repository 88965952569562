
import { CalendarIcon, CheckCircleIcon, CheckIcon, ClockIcon, MapPinIcon, PaperClipIcon, TagIcon } from '@heroicons/react/20/solid'
import { LineChart as RCLineChart, Line as RCLine } from 'recharts';
import Table from './components/table'
import { ChartBarIcon, GlobeAltIcon, TableCellsIcon, DocumentIcon, ArrowTrendingUpIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import FileView from './components/file-view';
import { useNavigate, useParams } from 'react-router-dom'
import { api } from './api.service';
import Loading from './components/loading';
import PageHeader from './components/page-header';
import { DateStringToDate } from './date.service';
import Button from './components/button';
import Dashboard from './dashboard';
import { format_currency, formatMetric } from './formatter'
import { Card, Metric, Text } from "@tremor/react";
import { LineChart, Title } from "@tremor/react";
import DocumentModal from './components/document-modal';
import Scorecard from './scorecard';
import AdminMetricsDisplay from './admin-metrics-display';
import ScorecardSummary from './scorecard-summary';
import ImageViewer from './components/image-viewer';

export default function AdminPc(props) {

  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [metric, setMetric] = useState(false)
  const [open2, setOpen2] = useState(false)
  const params = useParams();
  let id = params.id ?? props.id;
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [scorecardTab, setScorecardTab] = useState()


  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc-with-data/${id}`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>
  let scorecardCategories = data.scorecards?.slice().map(x => x.category).filter((value, index, array) => array.indexOf(value) === index)
  if (!scorecardTab && scorecardCategories && scorecardCategories.length) setScorecardTab(scorecardCategories[0])
  return (<>
    <Modal open={open} setOpen={setOpen} ></Modal>
    <Modal2 open={open2} setOpen={setOpen2} metric={metric}></Modal2>
    <PageHeader image={data.logo} headline={data.name} actions={<Button text="Edit" onClick={() => props.admin ? navigate('/2/manage-pc/' + data._id) : () => { }}></Button>}></PageHeader>

    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5 bg-white shadow rounded-lg">
          <Tabs ti={tab} setTab={setTab} customDashboards={data.dashboards}></Tabs>
          <div className=" p-5">

            {tab === 0 && <div>
              {data.coverPhoto && <ImageViewer className="w-full object-cover max-h-96 shadow rounded-md my-5" image={data.coverPhoto}></ImageViewer>}
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Company Name</dt>
                    <dd className="mt-1 text-md text-gray-800">{data.name}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Sector</dt>
                    <dd className="mt-1 text-md text-gray-800">{data.sector}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Invested Date</dt>
                    <dd className="mt-1 text-md text-gray-800">{new Date(data.date)?.toDateString()}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">CEO</dt>
                    <dd className="mt-1 text-md text-gray-800">{data.ceo}</dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">About</dt>
                    <dd className="mt-1 text-lg text-gray-700">
                      {data.about}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>}

            {tab === 1 && <div className="sm:col-span-2">

              <AdminMetricsDisplay _pc={data._id}></AdminMetricsDisplay>

            </div>}

            {tab === 2 && <div className="sm:col-span-2 mb-10">

              <div className="flex select-none">
                <div className="mx-auto flex">
                  {scorecardCategories?.map((x) => <div className={`${x === scorecardTab ? 'text-blue-500 border-b-4 border-blue-500' : 'text-neutral-600 border-b-2 hover:text-neutral-700 hover:border-b-2 cursor-pointer'} font-bold text-center px-5 py-2 text-2xl `} onClick={() => setScorecardTab(x)}>{x}</div>)}

                </div>
              </div>

              <div className="flex justify-between space-x-8 my-10">{data.scorecards?.filter(x => x.category == scorecardTab).map(x => <div className="flex-1"><ScorecardSummary metrics={data.metrics} _metricValues={data.metricValues} scorecard={x}></ScorecardSummary></div>)}</div>

              {data.scorecards?.filter(x => x.category == scorecardTab).map(x => <Scorecard metrics={data.metrics} _metricValues={data.metricValues} scorecard={x}></Scorecard>)}

            </div>}

            {tab === 3 && <div className="sm:col-span-2 mb-10">

              <div className="grid grid-cols-3 gap-4">
                {data.metrics.filter(x => x.type == 9).map(x => <div className="flex flex-col rounded-md shadow-md text-center text-xl text-neutral-700 font-semibold p-2">
                  <div>{x.name} </div>
                  <div className="mx-auto my-3">{formatMetric(data.metricValues.slice().reverse().find(y => y.metric == x._id).value, 9, metric, data.metricValues, data.metrics)}</div>
                </div>)}
              </div>

            </div>}

            {tab > 3 && <div className="sm:col-span-2">

              <Dashboard scorecards={data.scorecards} metrics={data.metrics} dashboard={data.dashboards[tab - tabs.length]} _metricValues={data.metricValues}></Dashboard>

            </div>}

          </div>

        </div>
      </div>
    </div >
  </>
  )
}

const tabs = [
  { name: 'Overview', href: '#', icon: GlobeAltIcon, current: true },
  { name: 'Data', href: '#', icon: TableCellsIcon, current: false },
  // { name: 'Form Responses', href: '#', icon: DocumentIcon, current: false },
  { name: 'Scorecards', href: '#', icon: CheckIcon, current: false },
  { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab, customDashboards }) {
  return (
    <div className="bg-neutral-100 rounded-t-lg px-5 pt-2 border-b border-gray-200 ">
      <div className="select-none">
        <div className="">
          <nav className="-mb-px flex space-x-8  overflow-x-auto w-full" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium shrink-0'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}

            {customDashboards?.map((dash, i) => (
              <a onClick={() => setTab(i + tabs.length)}
                key={dash.name}
                className={classNames(
                  i + tabs.length === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium shrink-0'
                )}
              >
                <ChartBarIcon
                  className={classNames(
                    i + + tabs.length === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{dash.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

function Modal({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <FileView></FileView>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Modal2({ open, setOpen, metric }) {
  if (!metric) return <></>
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">

                <Card>
                  <Title>{metric.name}</Title>
                  <LineChart
                    className="mt-6"
                    data={metric.data.map(x => { return { date: x.date.toDateString(), [metric.name]: +x.value } })}
                    index="date"
                    categories={[metric.name]}
                    colors={["blue"]}
                    valueFormatter={(number) => formatMetric(+number, metric.type)}
                  />
                </Card>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Sparkline({ data }) {

  const _data = data.map(x => { return { count: x } });


  return (
    <RCLineChart width={60} height={40} data={_data}>
      <RCLine dot={false} dataKey="count" stroke="#22c55e" strokeWidth={2} />
    </RCLineChart>
  )
}
import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { ArrowTrendingUpIcon, ChartBarIcon, Cog6ToothIcon, ComputerDesktopIcon, PencilIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Card, Metric, Text, LineChart, Title } from "@tremor/react";
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid'
import { formatMetric } from './formatter'
import { DateStringToDate } from './date.service';
import { Dialog, Transition } from '@headlessui/react'
import { LineChart as RCLineChart, Line as RCLine } from 'recharts';
import { ClipboardDocumentCheckIcon, SunIcon } from '@heroicons/react/24/outline'

export default function AdminMetrics() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [deleteId, setDeleteId] = useState();

  const [tab, setTab] = useState(0)
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/metric/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/data-metrics/${category}`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/data-metrics/${category}`).then(x => setData(x)) }, [tab])

  let category = tab == 2 ? 'esg' : tab == 1 ? 'metrics' : 'general'

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader subtitle={"Define the data points in your data framework broken down by General, Metrics & ESG"} headline={category == "metrics" ? 'Metrics' : category == "esg" ? 'ESG' : 'General Data'} actions={<Button text={category == "metrics" ? 'Add Metric' : category == "esg" ? 'Add ESG Metric' : 'Add Data Point'} onClick={() => navigate(`/2/manage-${category == "metrics" ? 'metric' : category == "esg" ? 'esg' : 'data-point'}/new`)}></Button>}></PageHeader>
    {data && <div className="flex transition ease-in">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">

        <Tabs ti={tab} setTab={setTab}>

        </Tabs>

        <div className="mt-5">
          {data && <Table
            exportColumns={['Portfolio Company', 'Investor', 'Invested', "Date"]} exportData={data.map(x => {
              return {
                pc: x.portfolioCompany?.name, investor: x.investor?.name, invested: x.invested, date: new Date(x.date).toDateString()
              }
            })}
            data={data.sort((a, b) => a.name > b.name ? -1 : 1).sort((a, b) => a.category > b.category ? 1 : -1).map(x => {
              return {
                ...x, autoCollect: x.autoCollect ? 'Yes' : 'No',
                type: [{ value: 0, text: "Number" }, { value: 1, text: "Text" }, { value: 2, text: "Date" }, { value: 3, text: "DateTime" }, { value: 4, text: "Percentage" }, { value: 5, text: "Currency" }, { value: 6, text: "Boolean" }, { value: 7, text: "Document" }].find(y => y.value === x.type)?.text,
                interval: [{ value: 5, text: "One Off" }, { value: 0, text: "Weekly" }, { value: 1, text: "Monthly" }, { value: 2, text: "Quarterly" }, { value: 3, text: "Bi-Annually" }, { value: 4, text: "Annually" }].find(y => y.value == x.interval)?.text,
                edit: <span className="cursor-pointer underline" onClick={() => navigate(`/2/manage-${category == "metrics" ? 'metric' : category == "esg" ? 'esg' : 'data-point'}/${x._id}`)}><PencilIcon className="w-6 text-neutral-400 hover:text-neutral-600"></PencilIcon></span>,
                deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}><TrashIcon className="w-6 text-neutral-400 hover:text-neutral-600"></TrashIcon></span>
              }
            })} columns={[
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'Category',
                accessor: 'category',
              },
              {
                Header: 'Type',
                accessor: 'type',
              },
              {
                Header: 'Interval',
                accessor: 'interval',
              },
              {
                Header: 'Edit',
                accessor: 'edit',
                width: 20
              },
              {
                Header: 'Delete',
                accessor: 'deleteCell',
                width: 20
              },
            ]}></Table>}
          {!data && <Loading></Loading>}
        </div>
      </div>
    </div>}
  </>
  )
}

const tabs = [
  { name: 'General', href: '#', icon: ClipboardDocumentCheckIcon, current: true },
  { name: 'Metrics', href: '#', icon: TableCellsIcon, current: false },
  { name: 'ESG', href: '#', icon: SunIcon, current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab }) {
  return (
    <div>
      <div className="select-none">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}


          </nav>
        </div>
      </div>
    </div>
  )
}
